import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormArray, FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormControl } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { Globals } from '../../data/globals';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideNativeDateAdapter } from '@angular/material/core';
import { SignaturePadComponent } from '../../components/signature-pad/signature-pad.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationService } from '../../services/registration.service';
import { IPolicy } from '../../models/IPolicy';
import { ILocation } from '../../models/ILocation';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { IStudent } from '../../models/IStudent';
import { IDataForm } from '../../models/IDataForm';
import { GooglePlacesComponent } from '../../components/google-places/google-places.component';
import { environment } from '../../../environments/environment';
import { IParent } from '../../models/IParent';
import { FooterComponent } from '../../components/footer/footer.component';
import { PhoneNumberDirective } from '../../_shared/phone-number.directive';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { practiceData, PracticeDataItem } from '../../data/practice-data';
import { firstValueFrom } from 'rxjs';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";


@Component({
  selector: 'app-submit-form',
  standalone: true,
  imports: [
    MatCardModule,
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    MatDatepickerModule,
    SignaturePadComponent,
    MatCheckboxModule,
    MatDialogModule,
    GooglePlacesComponent,
    FooterComponent,
    PhoneNumberDirective,
    MatSnackBarModule,
    MatProgressSpinnerModule
  ],
  providers: [provideNativeDateAdapter()],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './submit-form.component.html',
  styleUrls: ['./submit-form.component.scss']
})
export class SubmitFormComponent implements OnInit {
  centers: Array<ILocation> = new Array<ILocation>();
  centerId!: string;
  genders!: string[];
  grades!: string[];
  relationships!: string[];
  phoneTypes!: string[];
  states!: string[];
  referredBySources!: string[];
  submittedData!: IDataForm;
  policy!: IPolicy;
  policyHtml: string = "";
  readonly dialog = inject(MatDialog);
  formSubmitted = false;
  form!: FormGroup;
  isPracticeCenter = false;
  practiceDataControl = new FormControl();
  practiceData: PracticeDataItem[] = [];
  isProcessing = false; // Submit processing

  constructor(
    private fb: FormBuilder,
    private regService: RegistrationService,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
  ) { }

  async ngOnInit(): Promise<void> {
    var self = this;

    this.centerId = this.activatedRoute.snapshot.params['centerId'];
    this.isPracticeCenter = this.centerId.includes('practice');

    try {
      this.regService.getJSONLocations().subscribe(resp => {
        this.centers = resp.body!;

        const center = (this.centers || []).find(x => this.centerId === x.slug);

        this.regService.getPolicy(center ? this.centerId : null).subscribe(resp => {
          this.policy = resp.body!;
        });
      });
    } catch (error) {
      console.error('Error fetching locations:', error);
    }

    this.genders = Globals.Genders;
    this.grades = Globals.Grades;
    this.relationships = Globals.Relationships;
    this.phoneTypes = Globals.PhoneTypes;
    this.states = Globals.States;
    this.referredBySources = Globals.ReferredBySources;

    if (this.isPracticeCenter) {
      this.loadPracticeData();
    }

    this.form = this.fb.group({
      students: this.fb.array([this.createStudent()]),
      address: this.fb.group({
        street: ['', Validators.required],
        city: ['', Validators.required],
        state: ['', Validators.required],
        zip: ['', Validators.required],
        address2: ['']
      }),
      answer: [''],
      parents: this.fb.array([this.createParent(true), this.createParent(false)]),
      referredBy: [''],
      policyAgreement: [false, Validators.requiredTrue],
      signature: ['', Validators.required]
    });

    this.clearForm();
  }

  loadPracticeData(): void {
    this.practiceData = practiceData;
  }

  prefillData(selectedItem: PracticeDataItem): void {
    //student
    const studentFormGroups = selectedItem.students.map((student) => {
      const studentGroup = this.createStudent();
      const dateOfBirth = student.dob ? new Date(student.dob) : null;
      studentGroup.patchValue({
        firstName: student.firstName,
        lastName: student.lastName,
        dob: dateOfBirth,
        school: student.school,
        grade: student.grade,
        gender: student.gender,
      });
      return studentGroup;
    });
    this.form.setControl('students', this.fb.array(studentFormGroups));
    //parent
    const parentFormGroups = selectedItem.parents.map((parent, i) => {
      const isRequired = i === 0;
      const parentGroup = this.createParent(isRequired);
      parentGroup.patchValue({
        name: parent.name,
        relationship: parent.relationship,
        phone: parent.phone,
        phoneType: parent.phoneType,
        email: parent.email,
      });
      return parentGroup;
    });
    this.form.setControl('parents', this.fb.array(parentFormGroups));
    // address
    const addressForm = this.form.get('address') as FormGroup;
    addressForm.patchValue({
      street: selectedItem.address.address1,
      city: selectedItem.address.city,
      state: selectedItem.address.state,
      zip: selectedItem.address.zip,
    });

    this.changeDetectorRef.detectChanges();
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }

  createStudent(): FormGroup {
    return this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      gender: [''],
      school: [''],
      dob: [''],
      grade: ['', Validators.required]
    });
  }

  createParent(isRequired: boolean): FormGroup {
    return this.fb.group({
      name: ['', isRequired ? Validators.required : []],
      relationship: [''],
      phone: ['', isRequired ? Validators.required : []],
      phoneType: [''],
      email: ['', isRequired ? [Validators.email] : []],
    });
  }

  get students(): FormArray {
    return this.form.get('students') as FormArray;
  }

  get parents(): FormArray {
    return this.form.get('parents') as FormArray;
  }

  get address(): FormGroup {
    return this.form.get('address') as FormGroup;
  }

  addStudent(): void {
    this.students.push(this.createStudent());
  }

  deleteStudent(index: number): void {
    this.students.removeAt(index);
  }

  generatePolicyHtml(): void {
    if (this.policy) {
      let count = 1;
      this.policy.sections.forEach(sec => {
        if (count == 1) {
          this.policyHtml += "<p class='mat-mdc-card-subtitle'>" + sec.title + '</p>';
        } else {
          this.policyHtml += "<p class='mat-mdc-card-subtitle mt-3'>" + sec.title + '</p>';
        }

        this.policyHtml += '<ul>';
        sec.rules.forEach(rul => {
          this.policyHtml += '<li>' + count + '. ' + rul + '</li>';
          count++;
        });
        this.policyHtml += '</ul>';
      });

      this.policy.footer.forEach(foot => {
        this.policyHtml += "<p class='mt-3'>" + foot + '</p>';
      });
    }
  }

  openDialog() {
    this.generatePolicyHtml();

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Rules and Policies',
        content: this.policyHtml,
        hideCancelButton: true,
        submitButtonText: 'I Agree'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  clearForm(): void {
    this.form.reset();
    this.form.setControl('students', this.fb.array([this.createStudent()]));
    this.form.setControl('parents', this.fb.array([this.createParent(true), this.createParent(false)]));
  }

  submitForm(): void {
    // Show loading icon while isProcessing = true
    this.isProcessing = true;
    this.formSubmitted = true;
    if (this.form.valid) {
      const formData = this.form.value;
      const studentsWithAddress = formData.students.map((student: any) => ({
        ...student,
        address: `${formData.address.street} ${formData.address.address2}`.trim(),
        city: formData.address.city,
        state: formData.address.state,
        zip: formData.address.zip,
      }));

      this.submittedData = {
        info: studentsWithAddress,
        key: environment.key,
        dateSubmitted: new Date(),
        centerId: this.centerId,
        answer: formData.answer ?? '',
        parents: formData.parents,
        policy: this.policy,
        referredBy: formData.referredBy ?? '',
        signature: formData.signature
      };

      this.regService.submitForm(this.submittedData).subscribe(
        (result) => {
          this.isProcessing = false;
          this.snackBar.open('Registration submitted successfully!', 'Close', {
            duration: 5000,
            panelClass: 'success-snackbar',
            verticalPosition: 'bottom',
            horizontalPosition: 'center'
          });
          this.clearForm();
          this.router.navigate([`${this.centerId}/success`]);
        },
        (error) => {
          this.isProcessing = false
          this.snackBar.open(`Error submitting form. Please try again later.`, 'Close', {
            duration: 5000,
            panelClass: 'error-snackbar',
            verticalPosition: 'bottom',
            horizontalPosition: 'center'
          });
        }
      );
    } else {
      this.form.markAllAsTouched();
      this.formSubmitted = false;
      this.isProcessing = false;
    }
  }

  signed(data: any): void {
    this.form.patchValue({ signature: data });
  }
}
