import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ILocation } from '../models/ILocation';
import { IPolicy } from '../models/IPolicy';
import { IDataForm } from '../models/IDataForm';
import { environment } from '../../environments/environment';
import { catchError, map, Observable, of, tap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  private _dataUrl: string = '/assets/data/locations-2025.json';
  private policyPath = '/assets/policies/';
  private defaultFile = 'policies-default.json';
  private apiUrl: string = "";

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  getJSONLocations(): Observable<HttpResponse<Array<ILocation>>> {
    return this.http.get<Array<ILocation>>(this._dataUrl, { observe: 'response' });
  }

  getPolicy(centerId: string | null): Observable<HttpResponse<IPolicy>> {
    const filePath = `${this.policyPath}policies-${centerId}.json`;
  
    // Try to fetch the specified file, and fallback to the default file if not found
    return this.http.get<IPolicy>(filePath, { observe: 'response' }).pipe(
      catchError(() =>
        this.http.get<IPolicy>(`${this.policyPath}${this.defaultFile}`, { observe: 'response' })
      )
    );
  }

  submitForm(data: IDataForm): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: environment.key
      })
    };

    return this.http.post(this.apiUrl + "/registration", data, httpOptions)
      .pipe(
        map(result => ({ data: result })),
        catchError(error => {
          return this.handleError('There was an error during registration', error);
        })
      );

  }

  private handleError(operation = 'operation', error: any): Observable<never> {
    console.error(`${operation} failed:`, error);

    const errorMessage = this.getErrorMessage(error);

    return throwError(errorMessage);
  }

  private getErrorMessage(error: any): string {
    if (error.error instanceof ErrorEvent) {
      return `An error occurred: ${error.error.message}`;
    } else {
      return `Server returned code ${error.status} with message: ${error.message}`;
    }
  }
}
